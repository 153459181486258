<template>
  <v-row class="about">
    <staff :slides="slides"/>
    <v-col cols="12" class="col-sm-8 order-sm-2">
      <h1>About Us</h1>

      <p>
        We welcome ALL who want to dance. Our instructors are well
        educated in the art of dance with qualified dance experience
        and training. They are also all amazing performers and
        choreographers. Each class has a curriculum set to be sure that
        every student achieves their full potential. Dance classes are
        available for all ages, sizes and levels. We offer multiple
        dance styles including contemporary, hip hop, jazz funk,
        technical jazz and ballet, plus specialty classes for pre school
        and adults.
      </p>
      <p>
        CTB stands out from other dance studios by keeping
        our set schedule year round with no summer break so your dancer
        can continue to improve. Summer intensives are also available.
        At CTB we have something for everyBODY... Just want to have fun
        in a recreational setting? Want more serious classes with higher expectations?
        Interested in performance team? Want to compete at competition?
        We are the dance studio for you!
      </p>
    </v-col>
    <v-col class="order-3">
      <!--more pictures here-->
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import Staff from '@/components/Staff.vue'

export default {
  name: 'Waiver',

  components: {
    Staff
  },

  data: () => ({ }),

  computed: {
    slides () {
      return this.$store.state.aboutSlides;
    }
  },

  methods: { }
}
</script>

<style lang="scss" scoped>
  .about p {
    font-size: 22px;
  }

  @media (max-width: 959px) {
    .about p {
      font-size: 18px;
    }
  }

  @media (max-width: 599px) {
    .about p {
      font-size: 14px;
    }
  }
</style>
