
import { Vue, Component, Prop } from 'vue-property-decorator'

type Slides = { title: string; contents: {primary: string}[] };
let interval: NodeJS.Timeout;

@Component({
  name: 'Staff'
})
export default class Staff extends Vue {
  @Prop({ type: Array, default: () => [] }) slides!: Slides[];

  private position = { slide: 0, content: 0 };

  get slide () {
    return this.position.slide
      ? this.slides[this.position.slide - 1]
      : undefined;
  }

  get content () {
    return this.position.slide && this.position.content
      ? (this.slides[this.position.slide - 1] as Slides).contents[this.position.content - 1]
      : undefined;
  }

  rotate () {
    let { slide, content } = this.position;
    const slides: Slides = this.slides[slide - 1] as Slides;
    if (content >= slides.contents.length) {
      content = 1;
      slide = slide % this.slides.length + 1
    } else {
      content++;
    }

    this.position = { slide, content };
  }

  startRotation () {
    interval = setInterval(() => {
      this.rotate();
    }, 3000);
  }

  pauseRotation () {
    clearInterval(interval);
  }

  mounted () {
    this.position = { slide: 1, content: 1 };
    this.startRotation();
  }
}
